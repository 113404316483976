import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["bannerArray", "position", "screenName", "slots", "hasPlaceholder", "minWidth", "minHeight"];
import React, { useEffect, useState } from 'react';
import { Carousel } from '@magalu/stereo-ui/molecules';
import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';
import { useAdsProvider } from '../../../contexts/index';
import withLayoutProps from '../../../hocs/withLayoutProps';
import AdsBanner from '../AdsBanner';
import AdsBannerSkeleton from '../AdsBannerSkeleton';
import { useCookies } from '@magalu/mixer-utils';
var AdsCarousel = function AdsCarousel(_ref) {
  var data = _ref.data,
    _ref$static = _ref["static"],
    bannerArray = _ref$static.bannerArray,
    position = _ref$static.position,
    screenName = _ref$static.screenName,
    slots = _ref$static.slots,
    _ref$static$hasPlaceh = _ref$static.hasPlaceholder,
    hasPlaceholder = _ref$static$hasPlaceh === void 0 ? false : _ref$static$hasPlaceh,
    minWidth = _ref$static.minWidth,
    minHeight = _ref$static.minHeight,
    staticProps = _objectWithoutProperties(_ref$static, _excluded),
    structure = _ref.structure;
  var _useAdsProvider = useAdsProvider(),
    ads = _useAdsProvider.ads,
    getBannerBySlot = _useAdsProvider.getBannerBySlot,
    isBannersLoading = _useAdsProvider.isLoading;
  var _useState = useState([]),
    _useState2 = _slicedToArray(_useState, 2),
    banners = _useState2[0],
    setBanners = _useState2[1];
  var _useCookies = useCookies(),
    _useCookies$customerI = _useCookies.customerId,
    customerId = _useCookies$customerI === void 0 ? '' : _useCookies$customerI;
  var buildBannerMapper = function buildBannerMapper() {
    return function (banner) {
      return {
        data: data,
        "static": _extends({}, banner, {
          customerId: customerId,
          isBannerCarousel: true,
          position: position,
          screenName: screenName
        }),
        structure: structure
      };
    };
  };
  useEffect(function () {
    if (bannerArray) {
      var mapper = buildBannerMapper();
      var mappedBanners = bannerArray.map(mapper);
      setBanners(mappedBanners);
    }
  }, [bannerArray]);
  useEffect(function () {
    if (slots) {
      var bannersBySlot = slots.map(function (item) {
        var mappedBannersBySlot = getBannerBySlot(item.slot);
        return mappedBannersBySlot == null ? void 0 : mappedBannersBySlot.map(function (banner) {
          return _extends({}, banner, {
            adsPath: item == null ? void 0 : item.adsPath
          });
        });
      });
      var mappedBanners = bannersBySlot.map(function (bannerArr) {
        if (!bannerArr) return [];
        var mapper = buildBannerMapper();
        return bannerArr.map(mapper);
      });
      setBanners(mappedBanners.flat());
    }
  }, [ads, slots]);
  return React.createElement(React.Fragment, null, !!(banners == null ? void 0 : banners.length) && !isBannersLoading && React.createElement(Carousel, _extends({
    bg: "background.darker",
    height: "fit-content",
    width: "100%",
    overflow: "hidden"
  }, staticProps), banners.map(function (item, index) {
    return React.createElement(AdsBanner, {
      data: item.data,
      "static": _extends({}, item["static"], {
        index: index
      }),
      structure: item.structure,
      key: item["static"].trackId
    });
  })), React.createElement(AdsBannerSkeleton, {
    height: minHeight,
    isVisible: isBannersLoading && hasPlaceholder,
    width: minWidth
  }));
};
AdsCarousel.defaultProps = {
  data: {},
  "static": {},
  structure: {}
};
process.env.NODE_ENV !== "production" ? AdsCarousel.propTypes = {
  data: shape({}),
  "static": oneOfType([shape({
    hasPlaceholder: bool,
    minHeight: string,
    minWidth: string,
    position: string,
    slots: arrayOf(string)
  }), shape({
    bannerArray: arrayOf(shape({
      campaignId: string,
      customerId: string,
      height: string,
      imageUrl: string,
      index: number,
      redirectUrl: string,
      trackId: string,
      width: string
    })),
    hasPlaceholder: bool,
    minHeight: string,
    minWidth: string,
    position: string
  })]),
  structure: shape({
    cookies: shape({
      accountData: shape({
        customerId: string
      })
    })
  })
} : void 0;
export default withLayoutProps(AdsCarousel);