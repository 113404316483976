import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useRef, useState } from 'react';
import { number, shape, string } from 'prop-types';
import Showcase from '@magalu/stereo-ui/organisms/Showcase';
import { useAdsEvents } from '@magalu/mixer-graphql';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { useAdsProvider } from '../../../contexts/index';
import { usePublisher } from '@magalu/mixer-publisher';
import { useCookies } from '@magalu/mixer-utils';
import { parseAdsProducts } from './parseAdsProducts';
import { buildEventData } from './utils';
var AdsShowcase = function AdsShowcase(_ref) {
  var staticProps = _ref["static"],
    structure = _ref.structure,
    data = _ref.data;
  var _staticProps$carousel = staticProps.carouselConfig,
    carouselConfig = _staticProps$carousel === void 0 ? {} : _staticProps$carousel,
    slot = staticProps.slot,
    _staticProps$showcase = staticProps.showcase,
    showcase = _staticProps$showcase === void 0 ? {} : _staticProps$showcase,
    exhibition = staticProps.exhibition,
    titleAs = staticProps.titleAs,
    productTitleAs = staticProps.productTitleAs,
    jsonLD = staticProps.jsonLD,
    adsPath = staticProps.adsPath,
    placement = staticProps.placement,
    showInCashLabel = staticProps.showInCashLabel,
    showDiscount = staticProps.showDiscount,
    showDiscountOnlyCashInfo = staticProps.showDiscountOnlyCashInfo,
    inCashDescription = staticProps.inCashDescription;
  var ref = useRef(null);
  var _useAdsProvider = useAdsProvider(),
    ads = _useAdsProvider.ads,
    getProductsBySlot = _useAdsProvider.getProductsBySlot;
  var _useState = useState([]),
    _useState2 = _slicedToArray(_useState, 2),
    parsedProducts = _useState2[0],
    setParsedProducts = _useState2[1];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var _useCookies = useCookies(),
    _useCookies$customerI = _useCookies.customerId,
    customerId = _useCookies$customerI === void 0 ? '' : _useCookies$customerI;
  var eventData = buildEventData({
    customerId: customerId,
    exhibition: exhibition,
    placement: placement,
    showcase: showcase,
    structure: structure
  });
  var _useAdsEvents = useAdsEvents({
      adsPath: adsPath,
      customerId: customerId,
      data: data,
      structure: structure
    }),
    sendAdsImpressionEvents = _useAdsEvents.sendAdsImpressionEvents,
    sendAdsClickEvents = _useAdsEvents.sendAdsClickEvents;
  var handleProductClick = function handleProductClick(e, product) {
    publish('productlist:selectItem:click', _extends({}, eventData, product));
    sendAdsClickEvents([_extends({}, product, {
      position: (product == null ? void 0 : product.position) - 1
    })]);
  };
  var handleProductView = function handleProductView(product) {
    publish('product:viewItemList', _extends({}, eventData, product));
    sendAdsImpressionEvents([_extends({}, product, {
      position: (product == null ? void 0 : product.position) - 1
    })]);
  };
  useEffect(function () {
    if (ads && Object.keys((ads == null ? void 0 : ads.products) || {}).length > 0) {
      var products = getProductsBySlot(slot);
      setParsedProducts(parseAdsProducts(products));
    } else {
      setParsedProducts([]);
    }
  }, [ads]);
  return React.createElement(React.Fragment, null, !!(parsedProducts == null ? void 0 : parsedProducts.length) && React.createElement(Showcase, _extends({
    ref: ref,
    key: "ads-showcase-1",
    exhibition: exhibition,
    data: _extends({}, showcase == null ? void 0 : showcase.config, {
      products: parsedProducts
    }),
    jsonLD: jsonLD,
    titleAs: titleAs,
    productTitleAs: productTitleAs,
    showInCashInfo: showInCashLabel,
    showDiscount: showDiscount,
    showDiscountOnlyCashInfo: showDiscountOnlyCashInfo,
    showSponsoredSubTitle: true,
    onClick: handleProductClick,
    onProductView: handleProductView,
    inCashDescription: inCashDescription
  }, carouselConfig)));
};
process.env.NODE_ENV !== "production" ? AdsShowcase.propTypes = {
  data: shape({
    product: shape({})
  }),
  "static": shape({
    exhibition: string,
    limit: number
  }),
  structure: shape({})
} : void 0;
AdsShowcase.defaultProps = {
  data: {},
  "static": {
    exhibition: 'carousel',
    limit: 5
  },
  structure: {}
};
AdsShowcase.ssr = true;
export default withLayoutProps(AdsShowcase);