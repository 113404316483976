import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useState } from 'react';
import { bool, func, string, shape, arrayOf } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Hr from '@magalu/stereo-ui/atoms/HorizontalRule';
import currencyFormatter from '@magalu/stereo-ui-utils/currencyFormatter';
import { Icon } from '@magalu/stereo-ui-icons';
import scrollToTop from '@magalu/stereo-ui-utils/scrollToTop';
import { parsePlaceholders } from '@magalu/mixer-structure';
import { usePublisher } from '@magalu/mixer-publisher';
import { TYPE_FLOAT } from '../../../../commons/constants';
import { BuyPrice, ButtonFloat, Container, FloatBuyButton, PriceType, Text, Link, LinksContainer } from './BuyButtonFloat.styles';
var BuyButtonFloat = function BuyButtonFloat(_ref) {
  var floatBar = _ref.floatBar,
    questions = _ref.questions,
    product = _ref.product,
    handleButtonClick = _ref.handleButtonClick,
    buttonDisabled = _ref.buttonDisabled,
    visible = _ref.visible;
  var complementText = floatBar.complementText,
    buttonText = floatBar.buttonText,
    links = floatBar.links,
    showTitle = floatBar.showTitle,
    position = floatBar.position;
  var parsedLinks = parsePlaceholders(links, questions);
  var _ref2 = (product == null ? void 0 : product.price) || {},
    bestPrice = _ref2.bestPrice,
    price = _ref2.price,
    paymentMethodDescription = _ref2.paymentMethodDescription;
  var _ref3 = (product == null ? void 0 : product.installment) || {},
    quantity = _ref3.quantity,
    amount = _ref3.amount;
  var _useState = useState([]),
    _useState2 = _slicedToArray(_useState, 2),
    activeLinks = _useState2[0],
    setActiveLinks = _useState2[1];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish,
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  var backToTop = function backToTop() {
    return scrollToTop();
  };
  var handleActiveSection = function handleActiveSection(_ref4) {
    var eventValue = _ref4.eventValue,
      isVisible = _ref4.isVisible;
    if (isVisible && !activeLinks.includes(eventValue)) {
      setActiveLinks(function (oldActiveLinks) {
        return [].concat(_toConsumableArray(oldActiveLinks), [eventValue]);
      });
    } else if (activeLinks.indexOf(eventValue) > -1) {
      setActiveLinks(function (oldActiveLinks) {
        return oldActiveLinks.filter(function (value) {
          return value !== eventValue;
        });
      });
    }
  };
  useEffect(function () {
    subscribe('product:active:section', handleActiveSection);
    subscribe('backTo:top', backToTop);
    return function () {
      unsubscribe('product:active:section', handleActiveSection);
      unsubscribe('backTo:top', backToTop);
    };
  });
  return React.createElement(Container, {
    visible: visible,
    position: position
  }, React.createElement(Flex, {
    alignItems: "center",
    mb: ['0', '0.5rem']
  }, showTitle && React.createElement(Text, {
    onClick: function onClick() {
      return publish('product:title:click');
    }
  }, "".concat([product == null ? void 0 : product.title, product == null ? void 0 : product.reference].filter(Boolean).join(' - '))), React.createElement(BuyPrice, null, React.createElement("div", {
    "data-testid": "showcase-price"
  }, React.createElement(PriceType, null, React.createElement(Box, {
    fontWeight: "bold",
    mr: 2
  }, "".concat(currencyFormatter({
    value: bestPrice || price
  }), " ").concat(paymentMethodDescription || 'no Pix'), complementText && React.createElement(Box, {
    as: "small",
    fontWeight: "regular",
    fontSize: 1,
    ml: 1
  }, complementText)), React.createElement(Box, {
    as: "small",
    fontSize: 1,
    color: "text.base"
  }, "ou ".concat(quantity, "x de ").concat(currencyFormatter({
    value: amount
  }), " no cart\xE3o"))))), React.createElement(FloatBuyButton, null, React.createElement(ButtonFloat, {
    color: "secondary",
    startIcon: React.createElement(Icon, {
      name: "ShoppingBag",
      width: 32,
      height: 32
    }),
    disabled: buttonDisabled,
    size: "large",
    onClick: function onClick() {
      return handleButtonClick({
        typeButton: TYPE_FLOAT
      });
    },
    "data-testid": "float-button",
    full: true
  }, buttonText))), !!(links == null ? void 0 : links.length) && React.createElement(React.Fragment, null, React.createElement(Hr, null), React.createElement(LinksContainer, null, parsedLinks.map(function (_ref5) {
    var event = _ref5.event,
      label = _ref5.label,
      key = _ref5.key;
    return React.createElement(Link, {
      active: activeLinks.includes(key),
      key: "link-".concat(key),
      as: "span",
      color: "inherit",
      "data-testid": "link-knowmore",
      onClick: function onClick() {
        return publish(event);
      }
    }, label);
  }))));
};
process.env.NODE_ENV !== "production" ? BuyButtonFloat.propTypes = {
  buttonDisabled: bool,
  floatBar: shape({
    buttonText: string,
    complementText: string,
    hideOnTop: bool,
    links: arrayOf(shape({})),
    position: string,
    showTitle: bool
  }),
  handleButtonClick: func,
  product: shape(),
  questions: shape({}),
  visible: bool
} : void 0;
BuyButtonFloat.defaultProps = {
  buttonDisabled: false,
  floatBar: {
    buttonText: 'Adicionar à sacola',
    complementText: '',
    hideOnTop: false,
    links: [],
    position: 'bottom',
    showTitle: false
  },
  handleButtonClick: function handleButtonClick() {},
  product: {},
  questions: {},
  visible: true
};
export default BuyButtonFloat;