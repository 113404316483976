export var buildEventData = function buildEventData(_ref) {
  var _structure$route, _showcase$config, _showcase$config2, _structure$route2;
  var customerId = _ref.customerId,
    structure = _ref.structure,
    showcase = _ref.showcase,
    placement = _ref.placement,
    exhibition = _ref.exhibition;
  return {
    customer_id: customerId,
    display: 'gallery',
    filters: structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.filters,
    list: showcase == null ? void 0 : (_showcase$config = showcase.config) == null ? void 0 : _showcase$config.title,
    origin: "ads:".concat(placement),
    pageName: structure == null ? void 0 : structure.name,
    showcase: {
      placeId: placement,
      title: showcase == null ? void 0 : (_showcase$config2 = showcase.config) == null ? void 0 : _showcase$config2.title,
      type: exhibition
    },
    term: structure == null ? void 0 : (_structure$route2 = structure.route) == null ? void 0 : _structure$route2.term,
    type: 'recomendacao'
  };
};